<script setup>
import { getDataObjectById, getOrCreateDataObject } from 'o365-dataobject';
import { ref, defineExpose } from 'vue';
import { OOrgUnitsLookup, OActivityTypesLookup } from 'o365-system-lookups';

const props = defineProps({
    DataObjectToReload: {
        type: Object,
        required: false
    },
    sjaID: Number
});

let dsActivities = getDataObjectById(`dsActivities_${props.sjaID}`)

const OrgUnit_ID = ref();
const OrgUnit = ref();
const OrgUnitIdPath = ref();
const AccessIdPath = ref();
const ActivityType_ID = ref();
const ActivityType = ref();
const Name = ref();

const dsActivitiesCreate = getOrCreateDataObject({
    id: 'dsActivitiesCreate',
    viewName: 'atbv_Complete_Activities',
    uniqueTable: 'atbv_Complete_Activities',
    distinctRows: false,
    maxRecords: -1,
    allowInsert: true,
    allowUpdate: true,
    allowDelete: true,
    disableAutoLoad: true,
    fields: [
        { name: "PrimKey", type: "string" },
        { name: "ID", type: "Number" },
        { name: "Name", type: "string" },
        { name: "OrgUnit_ID", type: "Number" },
        { name: "ActivityType_ID", type: "Number" }
    ]
});


const createNewActivityDialog = ref();
const isExecuting = ref(false);

defineExpose({ show });

async function show() {
    OrgUnit_ID.value = "";
    OrgUnit.value = "";
    OrgUnitIdPath.value = "";
    AccessIdPath.value = "";
    ActivityType_ID.value = "";
    ActivityType.value = "";
    Name.value = "";
    createNewActivityDialog.value.show();
}

function addActivity(pRow) {
    dsActivities.createNew({
        SJA_ID: props.sjaID,
        Activity_ID: pRow.ID
    });
}

async function createActivity() {
    isExecuting.value = true;
    try {
        await dsActivitiesCreate.createNew({
            OrgUnit_ID: OrgUnit_ID.value,
            OrgUnitIdPath: OrgUnitIdPath.value,
            AccessIdPath: AccessIdPath.value,
            Name: Name.value,
            ActivityType_ID: ActivityType_ID.value,
        });
    } catch (error) {
        console.error('Error creating activity:', error);
    }
}

dsActivitiesCreate.on("AfterSave", async function (options, row) {
    if (options.operation === "create") {
        createNewActivityDialog.value.hide();
        await addActivity(row);
        props.DataObjectToReload?.load();
    }
    isExecuting.value = false;
});

</script>

<template>
    <OModal ref="createNewActivityDialog">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="hstack gap-2">
                        <h4 class="modal-title" id="staticBackdropLabel">
                            {{ $t("Create New Activity") }}
                        </h4>
                        <div v-if="isExecuting" class="spinner-border spinner-border-sm" style="0.35rem;" role="status">
                            <span class="visually-hidden">{{ $t('Processing...') }}</span>
                        </div>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body d-flex flex-column">
                    <div class="mt-3">
                        <label>{{ $t("Name") + ' *' }}</label>
                        <input type="text" class="form-control" v-model="Name" required>
                    </div>
                    <div class="mt-3">
                        <label>{{ $t("Org Unit") + ' *' }}</label>
                        <OOrgUnitsLookup textInput required
                            :bind="sel => { OrgUnit_ID = sel.ID; OrgUnit = sel.Name; OrgUnitIdPath = sel.IdPath; AccessIdPath = sel.AccessIdPath }"
                            class="form-control" v-model="OrgUnit" />
                    </div>
                    <div class="mt-3">
                        <label>{{ $t("Activity Type") + ' *' }}</label>
                        <OActivityTypesLookup textInput required :textInputValue="ActivityType" :IdPath="OrgUnitIdPath" :disabled="!OrgUnitIdPath"
                            :bind="sel => { ActivityType_ID = sel.ID; ActivityType = sel.Name; }" class="form-control" />
                    </div>

                </div>

                <div class="modal-footer">
                    <button class="btn btn-outline-primary" :disabled="isExecuting" @click="createActivity">
                    {{ $t("Create Activity")}}</button>
                </div>
            </div>
        </div>
    </OModal>
</template>